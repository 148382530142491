:root {
  --color-bg: #fff;
  --color-light: #d9d9d9;
  --color-accent: #e8e500;
  --color-primary: #2c2137;
  --color-primary: #27242a;
}

html {
  height: 100%;
  font-family: "Raleway", sans-serif;
}

body {
  height: 100%;
  font-family: "Raleway", sans-serif;
  background: var(--color-bg);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
