@import "./style/mixin";

h1 {
  color: var(--color-bg);
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 59px;
  max-width: 14ch;
}
p {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  max-width: 35ch;
}
ul {
  list-style: none;
  display: flex;
  gap: 3em;
  padding: 0;
  flex-wrap: wrap;
}

a {
  position: relative;
  color: inherit;
  text-decoration: none;
  color: var(--color-accent);

  &:hover,
  &:focus {
    color: var(--color-primary);
    background: var(--color-accent);
  }

  &.anchor-strike::after {
    display: inline-block;
    position: absolute;
    content: "";
    left: 0;
    top: 1.5em;
    height: 4px;
    background: var(--color-bg);
    width: 0;
  }

  &.anchor-strike:hover::after,
  &.anchor-strike:focus::after {
    width: 100%;
    transition: width 200ms ease-in-out;
  }

  &.anchor-strike[data-hover-text] {
    &:hover::before,
    &:focus::before {
      display: inline-block;
      position: absolute;
      content: attr(data-hover-text);
      background: var(--color-accent);
      color: var(--color-primary);
      padding: 0.5em;
      width: 100%;
      top: -4em;
      left: 0;
    }
  }
}

section {
  &.App {
    display: grid;
    grid-template-rows: 60px repeat(2, 1fr);
  }
  &.header {
    background: var(--color-bg);
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid var(--color-primary);
    // box-shadow: 0px 3px 2px 1px var(--color-accent);
    display: flex;
    padding: 0 6em;
    a.headerLogo {
      display: flex;
      align-items: center;
      &:hover,
      &:focus {
        color: var(--color-primary);
        background: var(--color-accent);
        img {
          animation: imgHueRotate 2000ms linear infinite;
        }
      }
      img {
        height: 40px;
      }
    }
    ul {
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 auto;
      width: 50vw;
      padding: 0 5em;
      min-width: 320px;
      max-width: 900px;
      justify-content: space-between;
      li {
        flex: 1;
      }
      a {
        color: var(--color-primary);
        line-height: 2em;
        display: block;
        text-align: center;
      }
    }
  }
  &.hero {
    background: var(--color-primary);
    color: var(--color-accent);
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 15%;
    align-items: center;

    @include for-phone-only {
      grid-template-columns: 1fr;
    }
    /* clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%); */
    .left a.callToAction {
      background: var(--color-accent);
      color: var(--color-primary);
      padding: 0.5em 1em;
      display: inline-block;
      margin-top: 2em;
      font-size: large;

      &:hover,
      &:focus {
        transform: scale(1.1);
        transition: all 150ms ease-in-out;
      }
    }
  }
  &.left {
    padding: 5em 0;
    margin-inline-start: 10em;
    @include for-phone-only {
      margin-inline-start: 5em;
      margin-inline-end: 5em;
    }
    p {
      color: var(--color-bg);
    }
  }

  &.image {
    grid-row: span 2;
    overflow: hidden;
    @include for-phone-only {
      display: none;
    }
    img {
      height: 99vh;
      filter: grayscale(0.7);
      pointer-events: none;
    }
  }
  &.lab {
    background: var(--color-bg);
    color: var(--color-primary);
    display: grid;
    grid-template-columns: 0.25fr 1fr 2fr;
    grid-template-rows: 1fr 15%;
    height: 100vh;

    p {
      text-orientation: upright;
    }
  }
}

b.cmd {
  position: relative;
  width: 20px;
  height: 20px;
  background: var(--color-accent);
  left: -33px;
  top: 145px;
  display: inline-block;
  opacity: 1;
  animation: blink 1s 1200ms ease-in-out infinite alternate,
    imgHueRotate 10s 1200ms ease-in-out infinite alternate;

  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background: rgba(196, 196, 196, 0.3);
    left: -20px;
    top: 20px;
    animation: blink 500ms 700ms ease-in-out infinite alternate,
      imgHueRotate 12s 700ms ease-in-out infinite alternate;
  }

  &::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background: var(--color-accent);
    left: -20px;
    top: -20px;
    animation: blink 700ms 500ms ease-in-out infinite alternate,
      imgHueRotate 15s 500ms ease-in-out infinite alternate;
  }
}

@keyframes blink {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes imgHueRotate {
  0% {
    filter: hue-rotate(0);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
